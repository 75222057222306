export default function ViewOptions({
  isActive,
  setIsActive,
  value,
  setValue,
}) {
  return (
    <>
      <div className={"upload-options-container" + (isActive ? " active" : "")}>
        <div className="upload-options-header">
          <h2>Who can view this post</h2>
          <button onClick={() => setIsActive(false)}>
            <i className="fa fa-times" />
          </button>
        </div>
        <div className="options">
          <div
            className="option"
            onClick={() => setValue(0)}
            style={{ cursor: "pointer" }}
          >
            <h4>Everyone</h4>
            <input
              type="radio"
              name="view"
              className="radio-input"
              value={0}
              checked={value === 0}
              readOnly
              //   disabled
              //   onChange={(e) => setValue(0)}
            />
          </div>
          <div
            className="option"
            onClick={() => setValue(1)}
            style={{ cursor: "pointer" }}
          >
            <h4>Followers that you follow back</h4>
            <input
              type="radio"
              name="view"
              className="radio-input"
              value={1}
              checked={value === 1}
              readOnly
              //   disabled
              //   onChange={(e) => setValue(1)}
            />
          </div>
          <div
            className="option"
            onClick={() => setValue(2)}
            style={{ cursor: "pointer" }}
          >
            <h4>
              Only you <span>(Default)</span>
            </h4>
            <input
              type="radio"
              name="view"
              className="radio-input"
              value={2}
              checked={value === 2}
              readOnly
              //   disabled
              //   onChange={(e) => setValue(2)}
            />
          </div>
        </div>
      </div>
      <div
        className={"shadowed-overlay" + (isActive ? "" : " hidden")}
        onClick={() => setIsActive(false)}
      />
    </>
  );
}
