import { Link,useParams } from "react-router-dom";
import "./UserContent.css";
import { useContext, useEffect, useState } from "react";
import axios from "axios";
import VideoThumbnail from "./VideoThumbnail";
import Switch from "@mui/material/Switch";
import VideoIcon from '@mui/icons-material/VideoLibrary';
import PostIcon from '@mui/icons-material/PostAdd';
import FilterSlider from "../slider/slider";
import PostCard from "../postcard";
import { PostsContext } from "../../context/postcontext";
import MyCuratedCollections from "../CollectionCard";
export default function UserContent({ user }) {
  // list of random images
  const [content, setContent] = useState([]);
  const [filteredContent, setfilteredcontent] = useState([])
  const [filteredProducts, setfilteredProducts] = useState([])
  const [mode, setMode] = useState("video");
  const [products, setProducts] = useState([]);
  const { posts, setPosts } = useContext(PostsContext);
  const [filteredposts, setfilteredPosts] = useState([]);
  const contentRenderDelay = 125;
  const [activeFilter, setActiveFilter] = useState('All');
const {id}=useParams()
  const handleModeChange = () => {
    setActiveFilter("All")
    handleFilterClick("All")
    setMode((prevMode) => (prevMode === "video" ? "post" : "video"));
  };
  const handleFilterClick = (value) => {
    console.log(value)
    if (value === 'All') { setfilteredcontent(content); setfilteredPosts(posts); return; }

    setfilteredProducts(products.filter(product => product.tag === value));
    const filteredPosts = posts.filter(post =>
      post.products.some(product => product.Product.product_tag === value)
    );
    setfilteredPosts(filteredPosts)
  };
  useEffect(() => {
    const filteredcontent = content.filter(video => {

      return video.products.some(videoProductId => filteredProducts.some(product => product.id === videoProductId.toString()));
    });
    console.log(filteredcontent)
    setfilteredcontent(filteredcontent)

  }, [filteredProducts])

  const fetchproducts = () => {
    const url =
      process.env.REACT_APP_API_URL + "getProducts?user_id=" + user;
    const headers = {
      "Api-Key": process.env.REACT_APP_API_KEY,
      // "User-Id": "1",
    };

    console.log("fetching");

    axios
      .get(url, { headers })
      .then((response) => {
        if (response.data.code === 200) {
          const responseProducts = response.data.msg.map((data) => {
            let image = data.ProductImage[0].image;

            if (!image.startsWith("http://")) {
              image = "https://" + image;
            }
            return {
              id: data.Product.id,
              tag: data.Product.product_tag
            };
          });
          setProducts(responseProducts);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }

  const fetchposts = () => {
    const url =
      process.env.REACT_APP_API_URL + "getPostsByUser?user_id=" + user;
    const headers = {
      "Api-Key": process.env.REACT_APP_API_KEY,
    };

    console.log("fetching");

    axios
      .get(url, { headers })
      .then((response) => {
        setPosts(response.data);
        setfilteredPosts(response.data);
      })
      .catch((e) => {
        console.error(e);
      });
  }

  

  useEffect(() => {
    fetchproducts();
    fetchposts();

  }, []);

  useEffect(() => {
    const url = process.env.REACT_APP_API_URL + "showVideosAgainstUserID";
    const data = {
      user_id: user,
      starting_point: 0,
    };
    const headers = {
      "Content-Type": "application/json",
      "Api-Key": process.env.REACT_APP_API_KEY,
    };
    let ignore = false;

    axios
      .post(url, data, { headers: headers })
      .then((response) => {
        if (!ignore) {
          if (response.data.code === 200) {
            console.log(response.data);
            let storageData = response.data.msg.public.map((data) => {
              return {
                id: data.Video.id,
                video: data.Video.video,
                thumbnail: data.Video.thum,
                user: {
                  id: data.User.id,
                  profile_pic: data.User.profile_pic,
                  username: data.User.username,
                },
                description: data.Video.description,
                products: JSON.parse(data.Video.product_id),
              };
            });

            localStorage.setItem(
              process.env.REACT_APP_VIDEO_STORAGE_TOKEN,
              JSON.stringify(storageData)
            );
            setfilteredcontent(
              response.data.msg.public.map((data) => {
                let thumbnail = data.Video.thum;
                if (!thumbnail.startsWith("https")) {
                  thumbnail = "https://" + thumbnail;
                }
                return {
                  id: data.Video.id,
                  videoUrl: data.Video.video,
                  thumbnail,
                  products: JSON.parse(data.Video.product_id),
                  description:data.Video.description
                };
              })
            );
            setContent(
              response.data.msg.public.map((data) => {
                let thumbnail = data.Video.thum;
                if (!thumbnail.startsWith("https")) {
                  thumbnail = "https://" + thumbnail;
                }
                return {
                  id: data.Video.id,
                  videoUrl: data.Video.video,
                  thumbnail,
                  products: JSON.parse(data.Video.product_id),
                  description:data.Video.description
                };
              })
            );

          }
        }
      })
      .catch((error) => {
        console.error(error);
      });

    return () => {
      ignore = true;
    };
  }, [user]);

  return (
    <div className="flex flex-col gap-y-4 ">
     

<MyCuratedCollections />
<div className="flex justify-between">
<div>
<h1 className=" text-start ml-[5%] mt-4 text-2xl font-bold">Get My Fits !</h1>
      <p className="text-start text-sm text-gray-600 ml-[5%] font-medium">Shop Everything that I try on in my posts and videos !</p>
      </div>  
      

      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", borderColor:"#E91E63", borderRadius: "5px", padding: "5px" }}>
      <VideoIcon
        style={{ color: mode === "video" ? "#E91E63" : "black", cursor: "pointer", marginRight: "10px", fontSize: "2rem", background: "white", borderRadius: "50%", padding: "5px",  border: mode === "video" ? "2px solid #E91E63" : "2px solid #ccc"  }}
        onClick={handleModeChange}
      />
      <PostIcon
        style={{ color: mode === "post" ? "#E91E63" : "black", cursor: "pointer", marginLeft: "10px", fontSize: "2rem", background: "white", borderRadius: "50%", padding: "5px",  border: mode === "post" ? "2px solid #E91E63" : "2px solid #ccc"  }}
        onClick={handleModeChange}
      />
    </div>
    </div>
    <div className="w-[420px]" style={{ position: 'sticky', top: '0', zIndex: '1000' }}>
      <FilterSlider activeFilter={activeFilter} setActiveFilter={setActiveFilter} handleFilterClick={(value) => { handleFilterClick(value) }} />
      </div>

      
      <div className={""+mode==="video"?"user-content":"flex flex-col items-center w-[90%] justify-center mx-auto"}>
        {mode === "video" ? (
          <>
            {content.length > 0 ? (
              <div className="user-content-left">
                {filteredContent.map((data, index) =>
                  index % 2 !== 0 ? (
                    <Link
                      to={`/post/${data.id}?user=${user}`}
                      key={`left-${index}`}
                    >
                      <VideoThumbnail
                        videoUrl={data.videoUrl}
                        timeDelay={content.length * contentRenderDelay}
                        description={data.description}
                      />
                    </Link>
                  ) : null
                )}
              </div>
            ) : null}

            {content.length > 0 ? (
              <div className="user-content-right">
                {filteredContent.map((data, index) =>
                  index % 2 === 0 ? (
                    <Link
                      to={`/post/${data.id}?user=${user}`}
                      key={`right-${index}`}
                    >
                      <VideoThumbnail
                        videoUrl={data.videoUrl}
                        timeDelay={content.length * contentRenderDelay}
                        description={data.description}
                      />
                    </Link>
                  ) : null
                )}
              </div>
            ) : null}
          </>
        ) : (
          filteredposts?.map((post, index) => (
            <PostCard key={index} post={post} />
          ))
        )}
      </div>

    </div>
  );
}
