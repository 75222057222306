import "./ProductCard.css";

export default function ProductCard({
  type,
  data,
  isSelected,
  toggleProduct,
  removeProduct,
  isTemplate,
}) {
  return (
    <div
      className={"product-card" + (type ? "" : " outlined")}
      onClick={() => type && toggleProduct(data)}
    >
      {isTemplate ? (
        <></>
      ) : type ? (
        <div>
          <input type="checkbox" checked={isSelected} readOnly />
        </div>
      ) : (
        <button onClick={() => removeProduct(data.id)}>
          <i className="fa fa-trash" />
        </button>
      )}
      <div
        className={
          "product-image" + (isTemplate && data.pic === "" ? " isempty" : "")
        }
      >
        <img src={data.image} alt="" />
      </div>
      <div className="product-details">
        <div>
          <h4>{data.title}</h4>
          <p>{data.description}</p>
        </div>
        <div>
          <span>
            <i className="fa fa-rupee-sign" /> {Number(data.sale_price)}
          </span>
          <span className="strike-through">{Number(data.price)}</span>
        </div>
      </div>
    </div>
  );
}
