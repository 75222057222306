import { useEffect, useRef, useState } from "react";
import "../components/Posts/Products.css"; // Assuming this contains additional styles
import Draggable from "react-draggable";

function Product({ product }) {
  // regex to extract a link from string
  const reg = /(https?:\/\/[^\s]+)$/g;

  // extract the link from the string
  
console.log(product)
  return (
    <div className="w-72 product">
      <img className="w-[100%] " src={product.ProductImage[0].image} alt={product.Product.title} />
      <div className="product-info">
        <h3 className="text-xl">{product.Product.title.slice(0, 38)}</h3>
        <p className="">
          {/* rupees symbol font awesome */}
          <i className="fa fa-rupee-sign"></i>
          <span>{Number(product.Product.sale_price)}</span>
          <span className="strike-through">{Number(product.Product.price)}</span>
          <a href={product.Product.product_link} target="_blank" rel="noreferrer">
            View
          </a>
        </p>
      </div>
    </div>
  );
}

export default function ProductsforPost({ products, className, showProducts, setShowProducts }) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const prodRef = useRef(null);

  const handleDrag = (e, ui) => {
    if (ui.y > 0) {
      setPosition({ x: 0, y: ui.y });
    } else {
      ui.y = 0;
      setPosition({ x: 0, y: 0 });
    }
  };

  const snapPosition = (e, ui) => {
    if (!prodRef) return;

    const divheight = prodRef.current.clientHeight;

    if (ui.y < divheight / 4) {
      setPosition({ x: 0, y: 0 });
    }

    if (ui.y > (2 * divheight) / 3) {
      setShowProducts(false);
    }
  };

  useEffect(() => {
    if (!prodRef.current) return;

    if (showProducts) {
      setPosition({ x: 0, y: 0 });
    } else {
      setPosition({ x: 0, y: prodRef.current.clientHeight * 2 });
    }
  }, [showProducts]);

  return (
    <Draggable
      axis="y"
      handle="strong"
      position={position}
      onDrag={handleDrag}
      onStop={snapPosition}
    >
         <div className="flex justify-center">
      <div
        className={"products overflow-y-auto " + (showProducts ? "" : "hidden")+ " mx-auto"}
        ref={prodRef}
        style={{height:'500px',
            width: '440px' }} 
      >
        <strong>
          <h2>In this video</h2>
        </strong>
        <div className="flex flex-col w-96 gap-y-4 items-center">
          {products.map((product, index) => (
            <Product key={index} product={product} />
          ))}
        </div>
        <div className="dummy-white" />
      </div>
      </div>
    </Draggable>
  );
}
