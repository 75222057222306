import { useParams, useSearchParams } from "react-router-dom";
import ReelsScroll from "../components/Posts/ReelsScroll";
import axios from "axios";
import { useEffect, useState } from "react";

const Posts = () => {
  //   const reels = [
  //     "https://reelon.s3.ap-south-2.amazonaws.com/videos/1709037971566_Shorts_2000.mp4",
  //     "https://reelon.s3.ap-south-2.amazonaws.com/videos/5ce88292-f363-4ed1-b768-ab082a00aa57_Shorts_1300.mp4",
  //     "https://reelon.s3.ap-south-2.amazonaws.com/videos/shorts_67ea619d-a868-4b56-b790-29bd88cfc6ac_1708930440758_Shorts_2000.mp4",
  //     // Add more reels as needed
  //   ];

  const [reels, setReels] = useState([]);
  const { id } = useParams();
  const [URLSearchParams] = useSearchParams();

  const user = URLSearchParams.get("user");

  //   console.log(reels);

  useEffect(() => {
    let ignore = false;
    let videoFound = false;

    async function fetchData() {
      let storedData = localStorage.getItem(
        process.env.REACT_APP_VIDEO_STORAGE_TOKEN
      );
      if (!(storedData === undefined || storedData === null)) {
        try {
          let storedDataJson = JSON.parse(storedData);

          let reel = storedDataJson.find((data) => data.id === id);

          if (reel) {
            setReels([reel]);
            videoFound = true;
          }
        } catch (e) {
          console.log(e);
        }
      }

      if (id != 0 && !videoFound) {
        const url = process.env.REACT_APP_API_URL + "getVideo?video_id=" + id;
        const data = {
          user_id: user,
          starting_point: 0,
        };
        const headers = {
          "Content-Type": "application/json",
          "Api-Key": process.env.REACT_APP_API_KEY,
        };

        try {
          const response = await axios.get(url, data, { headers: headers });

          if (ignore) return;

          // console.log(response.data);

          if (response.data.code === 200) {
            let video = response.data.msg.Video.video;
            if (!video.startsWith("https://")) {
              video = "https://" + video;
            }

            let reel = {
              id: response.data.msg.Video.id,
              video: video,
              thumbnail: response.data.msg.Video.thum,
              user: {
                id: response.data.msg.User.id,
                profile_pic: response.data.msg.User.profile_pic,
                username: response.data.msg.User.username,
              },
              description: response.data.msg.Video.description,
              products: JSON.parse(response.data.msg.Video.product_id),
            };

            setReels((prevReels) => [
              reel,
              ...prevReels.filter((r) => r.id != id),
            ]);
          }
        } catch (e) {
          console.log(e);
        }
      }

      let url;
      if (user) {
        url = process.env.REACT_APP_API_URL + "showVideosAgainstUserID";
      } else {
        url = process.env.REACT_APP_API_URL + "showRelatedVideos";
      }

      const data = {
        video_id: id,
        user_id: user ? user : "1",
        device_id: "1",
        starting_point: "0",
      };
      const headers = {
        "Content-Type": "application/json",
        "Api-Key": process.env.REACT_APP_API_KEY,
      };

      try {
        const response = await axios.post(url, data, { headers: headers });
        // console.log(response.data);
        if (response.data.code === 200) {
          //   console.log(response.data);

          if (ignore) return;

          let responseVideos;

          if (user) {
            responseVideos = response.data.msg.public;
          } else {
            responseVideos = response.data.msg;
          }

          const responseReels = responseVideos
            .map((data) => {
              let video = data.Video.video;
              if (!video.startsWith("https")) {
                video = "https://" + video;
              }
              let user = {
                id: data.User.id,
                profile_pic: data.User.profile_pic,
                username: data.User.username,
              };
              return {
                id: data.Video.id,
                video,
                user,
                description: data.Video.description,
                products: JSON.parse(data.Video.product_id),
              };
            })
            .filter((data) => data.id !== id);

          setReels((prevReels) => [...prevReels, ...responseReels]);
        }
      } catch (e) {
        console.error(e);
      }
    }

    fetchData();

    return () => {
      ignore = true;
    };
  }, [id]);

  console.log(reels);

  // if (!user) {
  //   alert("Invalid Link");
  //   return <Navigate to="/" />;
  // }

  return <ReelsScroll reels={reels} />;
};

export default Posts;
