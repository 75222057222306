import React from 'react';
import { Card, CardHeader, CardContent, CardMedia, Typography } from '@mui/material';
import { Link, useNavigate, useParams } from 'react-router-dom';

const PostCard = ({ post }) => {
const navigate=useNavigate()
    let {id} = useParams();
    const calculateTimeAgo = (createdAt) => {
        const postDate = new Date(createdAt);
        const currentDate = new Date();
        const timeDifference = currentDate.getTime() - postDate.getTime();
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        if (days > 0) {
          return `${days} day${days === 1 ? '' : 's'} ago`;
        } else if (hours > 0) {
          return `${hours} hour${hours === 1 ? '' : 's'} ago`;
        } else if (minutes > 0) {
          return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
        } else {
          return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
        }
      };
  return (
    <div className='mb-6'>
  <Card onClick={()=>{navigate("photopost/"+post.id)}} >
      <CardHeader
      subheader={` ${calculateTimeAgo(post.created_at)}`}
      />
      <CardContent>
       
        {post.photos && post.photos.length > 0 && (
          <CardMedia
            component="img"
            height="200"
            image={"https://"+post.photos[0].Photo.url} 
            alt="Post image"
          />
        )}
         <Typography variant="body1" color="textSecondary" component="p">
          {post.description}
        </Typography>
      </CardContent>
    </Card>
    </div>
  );
};

export default PostCard;
