import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import MyCuratedCollections from '../components/CollectionCard';

const Collection = () => {
    const [Collectiondetail,setCollectiondetail]=useState({})
    const [products,setProducts]=useState([])
   const {id,collectionid}=useParams()
   console.log(id)

   function Product({ product }) {
    // regex to extract a link from string
    const reg = /(https?:\/\/[^\s]+)$/g;
  
    // extract the link from the string
    const link = product.description.match(reg)[0];
  
    return (
      <div className="product shadow-none border-r-2 border-b-2 rounded-none">
        <img className='rounded-none' src={product.image} alt={product.title} />
        <div className="product-info">
          <h3>{product.title.slice(0, 38)}</h3>
          <p>
            {/* rupees symbol font awesome */}
            <i className="fa fa-rupee-sign"></i>
            <span>{Number(product.sale_price)}</span>
            <span className="strike-through">{Number(product.price)}</span>
            <a href={link} target="_blank">
              View
            </a>
          </p>
        </div>
      </div>
    );
  }
    const fetchproducts=()=>{
        const url =
        process.env.REACT_APP_API_URL + "getCollectionsByUser?user_id=" + id+"&id="+collectionid;
      const headers = {
        "Api-Key": process.env.REACT_APP_API_KEY,
        // "User-Id": "1",
      };
      
      console.log("fetching");
      
      axios
        .get(url, { headers })
        .then((response) => {
         console.log(response.data)
              const Collectiondetails={
                byline:response.data.byline,
                description:response.data.description,
                created_at:response.data.created_at
              }
              setCollectiondetail(Collectiondetails)
            const responseProducts = response.data.products.map((data) => {
              let image = data.ProductImage[0].image;
      
              if (!image.startsWith("https://")) {
                image = "https://" + image;
              }
              return {
                image:image,
                sale_price:data.Product.sale_price,
                title:data.Product.title,
                price:data.Product.price,
                description:data.Product.description,
                id: data.Product.id,
                tag:data.Product.product_tag
              };
            });
           setProducts(responseProducts);
   
        })
        .catch((e) => {
          console.error(e);
        });
      }
      useEffect(()=>{fetchproducts()},[])
      

    return (
        <div className='mb-16 w-[420px]'>
            <h1 className='text-center mt-4 mb-2 font-bold text-2xl'>{Collectiondetail.byline}</h1>
            <h3 className='text-center text-gray-600 mt-4 mb-2 font-bold text-xl'>{Collectiondetail.description}</h3>
            <h2 className='text-center mb-3 font-medium'>Tagged Products {'('+products.length+')'}</h2>
        <div className=' mx-auto my-0  grid grid-cols-2 '>
        {products?.map((product, index) => (
            <Product key={product.id} product={product} />
        ))}
    </div>
    <MyCuratedCollections/>
    </div>
    );
};

export default Collection;



