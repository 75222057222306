import { useState } from "react";
import UploadOptions from "../components/Upload/UploadOptions";
import ViewOptions from "../components/Upload/ViewOptions";
import "../components/Upload/Upload.css";
import { Navigate, useNavigate } from "react-router-dom";
import ProductOptionSelector from "../components/Upload/ProductOptionSelector";
import ExistingProducts from "../components/Upload/ExistingProducts";
import NewProduct from "../components/Upload/NewProduct";
import ProductCard from "../components/Upload/ProductCard";
import axios from "axios";

export default function Upload({ parentUser }) {
  let [showOptions, setShowOptions] = useState(false);
  let [showViewOptions, setShowViewOptions] = useState(false);
  let [showProductOptions, setShowProductOptions] = useState(false);
  let [productType, setProductType] = useState(0);
  let [loading, setLoading] = useState(false);

  let [video, setVideo] = useState(null);
  let [videoUrl, setVideoUrl] = useState(null);
  let [description, setDescription] = useState("");
  let [view, setView] = useState(2);
  let [options, setOptions] = useState({
    likes: false,
    comments: false,
    mentions: false,
    videos: false,
  });
  let [products, setProducts] = useState([]);

  const navigate = useNavigate();

  if (!parentUser) {
    return <Navigate to="/login" state={{ redirect: "/new" }} />;
  }

  const viewOptions = [
    "Everyone",
    "Followers that you follow back",
    "Only you",
  ];

  const handleUpload = (e) => {
    if (e.target.files[0].type !== "video/mp4") {
      alert("Only mp4 videos are allowed.");
      return;
    }
    setVideo(e.target.files[0]);
    setVideoUrl(URL.createObjectURL(e.target.files[0]));
  };

  const removeProduct = (id) => {
    setProducts((products) => products.filter((product) => product.id !== id));
  };

  const insertProduct = (product) => {
    setProducts((products) => [...products, product]);
  };

  const goBack = (e) => {
    navigate(-1);
  };

  const handleSubmit = () => {
    if (!video) {
      alert("Upload a video!");
      return;
    }

    if (description === "") {
      alert("Add a description");
      return;
    }

    if (
      !products.length &&
      !window.confirm(
        "You have not added any products. Do you want to upload video without products?"
      )
    )
      return;

    alert("Uploading video...");
    setLoading(true);

    const url = process.env.REACT_APP_API_URL + "postVideoWithExistingProducts";
    const headers = {
      "Api-Key": process.env.REACT_APP_API_KEY,
      "Content-Type": "multipart/form-data",
      // "User-Id": 1,
      // "Content-Type": "application/json",
    };

    console.log("headers", headers);

    let formdata = new FormData();
    formdata.append("user_id", "1");
    formdata.append("video", video);
    formdata.append("description", description);
    formdata.append("allow_comments", options.comments);
    formdata.append("allow_duet", "1"); // to check
    formdata.append("story", "0"); // to check
    formdata.append("privacy_type", view === 2 ? "Private" : "Public");
    formdata.append(
      "products_json",
      JSON.stringify(products.map((prd) => prd.id))
    );

    // let formdata = {
    //   user_id: parentUser,
    //   video: video,
    //   description,
    //   allow_comments: options.comments,
    //   allow_duet: "1",
    //   story: "0",
    //   privacy_type: view === 2 ? "Private" : "Public",
    //   products_json: JSON.stringify(products.map((prd) => Number(prd.id))),
    // };

    for (let key of formdata.keys()) {
      console.log(key, formdata.get(key));
    }

    // console.log(formdata);

    axios
      .post(url, formdata, { headers: headers })
      .then((response) => {
        console.log(response.data);
        if (response.data.code === 200) {
          alert("Sucessfully uploaded new Reel");
          let video = response.data.msg.Video.video;

          if (!video.startsWith("https://")) {
            video = "https://" + video;
          }
          let reel = {
            id: response.data.msg.Video.id,
            video: video,
            thumbnail: response.data.msg.Video.thum,
            user: {
              id: response.data.msg.User.id,
              profile_pic: response.data.msg.User.profile_pic,
              username: response.data.msg.User.username,
            },
            description: response.data.msg.Video.description,
            products: JSON.parse(response.data.msg.Video.product_id),
          };

          let localStore = localStorage.getItem(
            process.env.REACT_APP_VIDEO_STORAGE_TOKEN
          );

          if (localStore) {
            localStore = JSON.parse(localStore);
            localStore.push(reel);
            localStorage.setItem(
              process.env.REACT_APP_VIDEO_STORAGE_TOKEN,
              JSON.stringify(localStore)
            );
          } else {
            localStorage.setItem(
              process.env.REACT_APP_VIDEO_STORAGE_TOKEN,
              JSON.stringify([reel])
            );
          }

          navigate("/post/" + reel.id);
        } else {
          alert("Could not upload the video try again later");
        }
      })
      .catch((e) => {
        alert("Could not upload the video. Try again later");
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="upload-container">
      <div className="upload-body">
        <div className="upload-header">
          <h2>New Post</h2>
          <button onClick={goBack}>
            <i className="fa fa-arrow-left"></i>
          </button>
        </div>
        <div className="upload-content">
          <textarea
            placeholder="Describe your post, add hashtags, or mention creators that inspired you"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={loading}
          />
          <div className="upload-video">
            {videoUrl ? (
              <video src={videoUrl} muted autoPlay loop></video>
            ) : (
              <div className="video-uploader">
                <input
                  type="file"
                  id="video"
                  accept=".mp4"
                  onChange={handleUpload}
                />
                <label htmlFor="video">
                  <i className="fa fa-plus" />
                  <p>Upload a video</p>
                </label>
              </div>
            )}
          </div>
        </div>
        <div className="upload-controls">
          <div
            className="upload-control"
            onClick={() => setShowViewOptions(true)}
            style={{ color: "rgb(255, 53, 87)", cursor: "pointer" }}
          >
            <i className="fa fa-lock icon" />
            <div>
              <h4>{viewOptions[view]} can view this post</h4>
            </div>
            <button>
              <i className="fa fa-chevron-right" />
            </button>
          </div>
          <div
            className="upload-control"
            onClick={() => setShowOptions(true)}
            style={{ cursor: "pointer" }}
          >
            <i className="fa fa-ellipsis-h icon" />
            <div>
              <h4>More options</h4>
              <p>Tap to manage comment, Duet and Stitch's preferences.</p>
            </div>
            <button>
              <i className="fa fa-chevron-right" />
            </button>
          </div>
          <div
            className="upload-control"
            onClick={() => setShowProductOptions(true)}
            style={{ cursor: "pointer" }}
          >
            <i className="fa fa-tag icon" />
            <div>
              <h4>Add Products</h4>
            </div>
            <button>
              <i className="fa fa-chevron-right" />
            </button>
          </div>
          <div className="products-container">
            <div className="upload-control">
              <i className="fa fa-tshirt icon" />
              <h4>Selected Products</h4>
            </div>
            {products.map((product) => (
              <ProductCard
                key={product.id}
                type={0}
                data={product}
                removeProduct={removeProduct}
              />
            ))}
          </div>
        </div>
      </div>
      <div className="upload-footer">
        <button onClick={() => {}} className="button-white" disabled={loading}>
          Drafts
        </button>
        <button
          onClick={() => {
            handleSubmit();
          }}
          className="button-red"
          disabled={loading}
        >
          Post{loading ? "ing" : ""}
        </button>
      </div>
      <UploadOptions
        isActive={showOptions}
        setIsActive={setShowOptions}
        value={options}
        setValue={setOptions}
      />
      <ViewOptions
        isActive={showViewOptions}
        setIsActive={setShowViewOptions}
        value={view}
        setValue={setView}
      />
      <ProductOptionSelector
        isActive={showProductOptions}
        setIsActive={setShowProductOptions}
        setValue={setProductType}
      />
      {productType === 1 ? (
        <ExistingProducts
          goBack={() => setProductType(0)}
          selected={products}
          setSelected={setProducts}
          parentUser={parentUser}
        />
      ) : (
        <></>
      )}
      {productType === 2 ? (
        <NewProduct
          goBack={() => setProductType(0)}
          insertProduct={insertProduct}
          parentUser={parentUser}
        />
      ) : (
        <></>
      )}
    </div>
  );
}
