import { Typography } from "@mui/material";
import React, { useRef, useState } from "react";
import { Movie } from "@mui/icons-material";
const VideoThumbnail = ({ videoUrl, timeDelay,description }) => {
  description=description.slice(0,22)
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [generatedThumbnail, setGeneratedThumbnail] = useState(false);

  const generateThumbnail = () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    if (!video || !canvas) return;

    canvas.height = video.videoHeight / (video.videoWidth / canvas.width);

    console.log(video.videoWidth, video.videoHeight, "drawing");

    canvas.getContext("2d").drawImage(video, 0, 0, canvas.width, canvas.height);

    setGeneratedThumbnail(true);
  };

  const onLoad = async () => {
    if (!videoRef.current) return;

    try {
      await videoRef.current.play();
    } catch (e) {
      console.error(e);
    }

    setTimeout(async () => {
      if (!videoRef.current) return;

      try {
        videoRef.current.pause();
        generateThumbnail();
      } catch (e) {
        console.error(e);
      }
    }, 0);
  };

  return (
    <div style={{ width: "100%" }}>
      {!generatedThumbnail ? (
        <video
          ref={videoRef}
          controls
          width="400"
          onLoadedMetadata={onLoad}
          muted
          autoPlay
          style={{ display: "none", }}
        >
          <source src={videoUrl} type="video/mp4" />
          Your browser does not support the video tag.
        </video>

      ) : (
        <></>
      )}
      <div style={{ position: "relative",}}>
        <canvas
          ref={canvasRef}
          width="400"
          height="225"
          style={{ display: "block", width: "100%", height: "100%" }}
        />
        <Movie
          style={{
            position: "absolute",
            top: "8px",
            left: "8px",
            color: "white",
            zIndex: 10,
            fontSize: "24px",
          }}
        />
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            right: 0,
            height: "50px", // Adjust height as needed
            background: "linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%)",
            pointerEvents: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="body2"
            style={{ color: "white", zIndex: 10, textAlign: "start",marginRight:"6px" }}
          >
            {description}
          </Typography>
        </div>
      </div>
      </div>
  );
};

export default VideoThumbnail;
