import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../slider/slider.css'; // Import CSS for custom styling
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTshirt, faCoins, faCouch, faBirthdayCake, faRunning, faLightbulb, faPalette, faCalendarAlt, faGlassCheers } from '@fortawesome/free-solid-svg-icons'; // Import Font Awesome icons

const FilterSlider = ({ handleFilterClick,activeFilter,setActiveFilter }) => {
  

  const filters = [
    { name: 'All', icon: faTshirt },
    { name: 'Dress', icon: faTshirt },
    { name: 'Luxury', icon: faCoins },
    { name: 'Loungewear', icon: faCouch },
    { name: 'TopWear', icon: faTshirt },
    { name: 'Occasionals', icon: faBirthdayCake },
    { name: 'Bottoms', icon: faRunning },
    { name: 'Getthelook', icon: faLightbulb },
    { name: 'Tops', icon: faPalette },
    { name: 'Casualfits', icon: faCalendarAlt },
    { name: 'Party wear', icon: faGlassCheers },
  ];

  const settings = {
    arrows: false,
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3, 
    slidesToScroll: 1,
    swipeToSlide: true,
    touchThreshold: 15, // Adjust the sensitivity of touch/swipe
  };

  const handleClick = (filter) => {
    handleFilterClick(filter);
    setActiveFilter(filter);
  
  };

  return (
    <div className="filter-slider w-[420px] bg-white mx-3 py-2">
      <Slider {...settings} className="flex space-x-4"> {/* Adjust gap between buttons */}
        {filters.map((filter) => (
          <div key={filter.name} className="flex items-center justify-center"> 
            <button
              onClick={() => handleClick(filter.name)}
              className={` inline-flex rounded-xl items-center justify-center w-fit min-w-24 ${activeFilter === filter.name ? 'bg-[#E91E63] border-[#E91E63] text-white' : 'border-black'} px-1 py-2 mx-2 border rounded-md`} // Adjust width, padding, and spacing
            >
              <FontAwesomeIcon className={`mr-1 text-sm ${activeFilter === filter.name ? ' text-white' : ''}`} icon={filter.icon} size="lg" />
              <span className='text-sm font-medium'>{filter.name}</span>
            </button>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default FilterSlider;
