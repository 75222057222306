import React, { useEffect, useRef, useState } from "react";
import "./Reel.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Products from "./Products";

function Reel({ videoUrl, index, meta, centralIsMuted, setCentralIsMuted }) {
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(true);
  const [products, setProducts] = useState({});
  const [showProducts, setShowProducts] = useState(false);

  const navigate = useNavigate();

  const togglePlay = async (e) => {
    if (
      !e.target.classList.contains("overlay") &&
      !e.target.classList.contains("fa-play")
    )
      return;

    if (showProducts) {
      setShowProducts(false);
      return;
    }
    // toggle play/pause
    // catch async error for multiple calls
    try {
      if (videoRef.current) {
        if (isPlaying) {
          if (!videoRef.current.paused) {
            videoRef.current.pause();
            setIsPlaying(false);
          }
        } else {
          await videoRef.current.play();
          setIsPlaying(true);
        }
      }
    } catch (e) {
      console.error(e);
    }
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !centralIsMuted;
      setCentralIsMuted((centralIsMuted) => !centralIsMuted);
    }
  };

  const goBack = () => {
    navigate(-1);
  };

  // auto start and stop video when user scrolls into/away from the video
  useEffect(() => {
    const observer = new IntersectionObserver(
      async ([entry]) => {
        if (!videoRef.current) return;
        // catch async error for multiple calls
        if (entry.isIntersecting) {
          // console.log("Playing", index);
          try {
            videoRef.current.load();
            await videoRef.current.play();
            setIsPlaying(true);
            setShowProducts(false);
          } catch (e) {
            console.error(e, videoRef.current);
          }
        } else {
          // console.log("Pausing", index);
          try {
            videoRef.current.muted = true;
            if (!videoRef.current.paused) {
              videoRef.current.pause();
              setIsPlaying(false);
            }
            setShowProducts(false);
          } catch (e) {
            console.log(e, videoRef.current);
          }
        }
      },
      { rootMargin: "-100px -100px -100px -100px" }
    );

    observer.observe(videoRef.current);

    return () => {
      if (!videoRef.current) return;

      observer.unobserve(videoRef.current);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      if (!videoRef.current) return;

      videoRef.current.muted = centralIsMuted || !isPlaying;
    }, 500);
  }, [centralIsMuted, isPlaying]);

  // console.log(meta.id, products);

  useEffect(() => {
    let ignore = false;

    // console.log(meta.products);

    if (!meta.products || !meta.products.length) {
      return;
    }

    for (let product of meta.products) {
      const url = process.env.REACT_APP_API_URL + "showProductDetail";
      const data = {
        user_id: "1",
        product_id: String(product),
      };
      const headers = {
        "Content-Type": "application/json",
        "Api-Key": process.env.REACT_APP_API_KEY,
      };

      axios
        .post(url, data, { headers: headers })
        .then((response) => {
          if (ignore) return;

          if (response.data.code === 200) {
            let product_image;
            if (response.data.msg.ProductImage.length === 0) {
              product_image = "";
            } else {
              product_image = response.data.msg.ProductImage[0].image;
            }
            if (!product_image.startsWith("https://")) {
              product_image = "https://" + product_image;
            }
            setProducts((prevProducts) => {
              return {
                ...prevProducts,
                [product]: {
                  ...response.data.msg.Product,
                  image: product_image,
                },
              };
            });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }

    return () => {
      ignore = true;
    };
  }, []);

  return (
    <section className={"video-container"}>
      <video
        ref={videoRef}
        loop
        muted={centralIsMuted}
        className="background-video"
      >
        <source src={videoUrl} type="video/mp4" />
      </video>
      <div className="overlay" onClick={togglePlay}>
        <div className="user-info">
          <div className="profile">
            <button className="back-btn" onClick={goBack}>
              <i className="fa fa-arrow-left"></i>
            </button>
            <img
              src={meta.user.profile_pic}
              alt="profile"
              onClick={() => {
                navigate("/user/a");
              }}
            />
            <b
              onClick={() => {
                navigate("/user/" + meta.user.id);
              }}
            >
              {meta.user.username}
            </b>
          </div>
          <button className="follow-btn" onClick={() => navigate("/new")}>
            Post Video
          </button>
          <button className="video-controls" onClick={toggleMute}>
            {centralIsMuted ? (
              <i className="fa fa-volume-mute" />
            ) : (
              <i className="fa fa-volume-up" />
            )}
          </button>
        </div>
        {isPlaying ? <></> : <i className="fa fa-play" />}
        <div className="product-info">
          {meta.products && meta.products.length ? (
            <button
              className="shop-btn"
              onClick={() => {
                setShowProducts(true);
              }}
            >
              {" "}
              <i className="fa fa-tag" /> Shop Products
            </button>
          ) : (
            <></>
          )}
          <span>{meta.description}</span>
        </div>
      </div>
      {meta.products && meta.products.length ? (
        <Products
          products={Object.values(products)}
          showProducts={showProducts}
          setShowProducts={setShowProducts}
        />
      ) : (
        <></>
      )}
    </section>
  );
}

export default Reel;
