import { useParams } from "react-router-dom";
import UserBio from "../components/User/UserBio";
import UserContent from "../components/User/UserContent";

export default function User() {
    let {id} = useParams();
    
    return (
        <section className="user-container">
            <UserBio user={id} />
            <UserContent user={id} />
        </section>
    )
}