import axios from "axios";
import "./ExistingProducts.css";
import ProductCard from "./ProductCard";
import { useEffect, useState } from "react";

export default function ExistingProducts({
  goBack,
  selected,
  setSelected,
  parentUser,
}) {
  let [products, setProducts] = useState([]);
  let [selectedIds, setSelectedIds] = useState([]);

  useEffect(() => {
    const url =
      process.env.REACT_APP_API_URL + "getProducts?user_id=" + parentUser;
    const headers = {
      "Api-Key": process.env.REACT_APP_API_KEY,
      // "User-Id": "1",
    };

    console.log("fetching");

    axios
      .get(url, { headers })
      .then((response) => {
        if (response.data.code === 200) {
          const responseProducts = response.data.msg.map((data) => {
            let image = data.ProductImage[0].image;

            if (!image.startsWith("http://")) {
              image = "https://" + image;
            }
            return {
              id: data.Product.id,
              title: data.Product.title,
              description: data.Product.description,
              price: data.Product.price,
              sale_price: data.Product.sale_price,
              image: image,
            };
          });

          setProducts(responseProducts);
        }
      })
      .catch((e) => {
        console.error(e);
      });
  }, []);

  useEffect(() => {
    setSelectedIds(selected.map((product) => product.id));
  }, [selected]);

  const toggleProduct = (product) => {
    if (selectedIds.includes(product.id)) {
      setSelected(selected.filter((p) => p.id !== product.id));
    } else {
      setSelected((selected) => [...selected, product]);
    }
  };

  return (
    <div className="existing-products-container">
      <div className="upload-header">
        <h2>Add Products</h2>
        <button onClick={goBack}>
          <i className="fa fa-arrow-left"></i>
        </button>
      </div>
      <div className="existing-products-body">
        <h3>Select products to add</h3>
        {products.length ? (
          products.map((product) => (
            <ProductCard
              key={product.id}
              type={1}
              data={product}
              isSelected={selectedIds.includes(product.id)}
              toggleProduct={toggleProduct}
            />
          ))
        ) : (
          <p style={{ color: "gray" }}>No products to show</p>
        )}
      </div>
    </div>
  );
}
