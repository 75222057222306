import { useState } from "react";
import "./Login.css";
import axios from "axios";

export default function LoginBox({ type, setUser }) {
  let [data, setData] = useState({
    password: "",
    [type]: "",
  });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    console.log(data);

    const url = process.env.REACT_APP_API_URL + "login";
    const headers = {
      "Api-Key": process.env.REACT_APP_API_KEY,
    };

    axios
      .post(url, data, { headers: headers })
      .then((response) => {
        if (response.data.code === 200) {
          setUser(response.data.msg.User.id);
          localStorage.setItem(
            process.env.REACT_APP_USER_STORAGE_TOKEN,
            response.data.msg.User.id
          );
        }
      })
      .catch((e) => {
        console.error(e);
      });
  };

  // console.log(data);

  return (
    <form className="login-box" onSubmit={handleSubmit}>
      <h2>
        Login with {type.at(0).toUpperCase() + type.slice(1, type.length)}
      </h2>
      <div className="input-group">
        <input
          type={type}
          name={type}
          value={data[`${type}`]}
          onChange={handleChange}
          required
        />
        <label>{type.at(0).toUpperCase() + type.slice(1, type.length)}</label>
      </div>
      <div className="input-group">
        <input
          type="password"
          name="password"
          value={data.password}
          onChange={handleChange}
          required
        />
        <label>Password</label>
      </div>
      <button type="submit">Login</button>
    </form>
  );
}
