import React, { useContext, useEffect, useState } from 'react';
import { Card, CardMedia, CardContent, Typography, Button, Box } from '@mui/material';
import axios from 'axios';

import { CollectionContext } from '../context/collectioncontext';
import { Link, useParams } from 'react-router-dom';

const AllCollections = () => {
    const {id}=useParams()
    const {Collection,setCollection}=useContext(CollectionContext)
    const fetchcollections = (id) => {
        const url =
          process.env.REACT_APP_API_URL + "getAllCollections?user_id=" + id;
        const headers = {
          "Api-Key": process.env.REACT_APP_API_KEY,
        };
    
        console.log("fetching");
    
        axios
          .get(url, { headers })
          .then((response) => {
           
            console.log(response.data)
            setCollection(response.data);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    
useEffect(()=>{
    
    if(Collection.length==0){
        console.log("called")
    fetchcollections(id)}
},[Collection.length])
  return (
   
  <Box sx={{ padding: 2 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <h1 className='font-bold text-xl'  gutterBottom>Showing all {Collection.length} Collections</h1>
     
      </Box>
     
      <Typography variant="body2" fontSize={"small"} color="textSecondary" paragraph>
        Easy to style collections that are must haves this season. Check'em out!
      </Typography>
    
      <div className='flex-col flex items-center justify-center'>
       {Collection.length && Collection.map((collection)=>( <Card  key={collection.Collection.id}  sx={{ marginBottom: 4,width:"80%" }}>
       <Link to={"/user/"+id+"/collection/"+collection.Collection.id}><CardMedia
            component="img"
            height="500"
            image={(collection?.Collection.image_link).startsWith('https://') ? collection?.Collection.image_link : `https://${collection?.Collection.image_link}`}
            alt={collection?.Collection.byline}
          />
          <CardContent>
            <div className='flex flex-col items-center justify-center'>
            <h1 className='font-bold text-xl mb-4'>{collection.Collection.byline}</h1>
            <p className='font-medium' variant="body2" color="textSecondary">
              {collection.Collection.description}
            </p>
            <button className='mt-1 text-white px-3 py-1 text-lg rounded-xl bg-[#E91E63]'>
              Shop Now
            </button>
            </div>
          </CardContent>
          </Link>
        </Card>
      
       ))
}
</div>
    </Box>
  
  );
};

export default AllCollections;
