import "./UploadOptions.css";

export default function UploadOptions({
  isActive,
  setIsActive,
  value,
  setValue,
}) {
  const handleChange = (e) => {
    setValue((previous) => {
      return {
        ...previous,
        [e.target.name]: e.target.checked,
      };
    });
  };

  return (
    <>
      <div className={"upload-options-container" + (isActive ? " active" : "")}>
        <div className="upload-options-header">
          <h2>More Options</h2>
          <button onClick={() => setIsActive(false)}>
            <i className="fa fa-times" />
          </button>
        </div>
        <div className="options">
          <div className="option">
            <h4>Likes</h4>
            {/* slider toggle button */}
            <div className="slider-toggle">
              <input
                type="checkbox"
                id="likes"
                name="likes"
                checked={value.likes}
                onChange={handleChange}
              />
              <label htmlFor="likes"></label>
            </div>
          </div>
          <div className="option">
            <h4>Comments</h4>
            {/* slider toggle button */}
            <div className="slider-toggle">
              <input
                type="checkbox"
                id="comments"
                name="comments"
                checked={value.comments}
                onChange={handleChange}
              />
              <label htmlFor="comments"></label>
            </div>
          </div>
          <div className="option">
            <h4>Mentions and tags</h4>
            {/* slider toggle button */}
            <div className="slider-toggle">
              <input
                type="checkbox"
                id="mentions"
                name="mentions"
                checked={value.mentions}
                onChange={handleChange}
              />
              <label htmlFor="mentions"></label>
            </div>
          </div>
          <div className="option">
            <h4>Videos from accounts you follow</h4>
            {/* slider toggle button */}
            <div className="slider-toggle">
              <input
                type="checkbox"
                id="videos"
                name="videos"
                checked={value.videos}
                onChange={handleChange}
              />
              <label htmlFor="videos"></label>
            </div>
          </div>
        </div>
      </div>
      <div
        className={"shadowed-overlay" + (isActive ? "" : " hidden")}
        onClick={() => setIsActive(false)}
      />
    </>
  );
}
