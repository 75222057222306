import React, { useContext, useEffect } from 'react';
import { AppBar, Toolbar, Typography, IconButton, Avatar } from '@mui/material';
import {  useNavigate, useParams } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { UserContext } from './context/user';
import axios from 'axios';
import { color } from '@mui/system';

const Layout = ({ children }) => {
    const navigate=useNavigate()
    const {User,setUser}=useContext(UserContext)
    const goBack = () => {
       navigate(-1)
    };
    const {id}=useParams()
        useEffect(() => {
            let ignore = false;
        
            const url = process.env.REACT_APP_API_URL + "showUserDetail";
            const data = {
              user_id: id,
            };
            const headers = {
              "Content-Type": "application/json",
              "Api-Key": process.env.REACT_APP_API_KEY,
            };
        
            axios
              .post(url, data, { headers: headers })
              .then((response) => {
                if (ignore) return;
                if (response.data.code === 200) {
                  setUser({
                    username: response.data.msg.User.username,
                    profile_pic: response.data.msg.User.profile_pic,
                    bio: response.data.msg.User.bio,
                  });
                }
              })
              .catch((e) => {
                console.error(e);
              });
        
            return () => {
              ignore = true;
            };
          }, [User]);
     

    return (
        <div className='w-[420px] border-x-2 border-gray-100 block mx-auto'>
            <AppBar sx={{background:"white",boxShadow:"none",borderBottom:"1px solid #e5e7eb"}} position="sticky">
                <Toolbar>
                    {/* Go back icon */}
                    <IconButton onClick={goBack} edge="start" sx={{color:"black"}} aria-label="menu">
                        <ArrowBackIcon />
                    </IconButton>

                    {/* Name and profile icon */}
                    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
                        <Avatar alt="User Avatar" src={User.profile_pic} />
                        <Typography variant="h7" style={{ marginLeft: '10px' ,fontWeight:"medium",color:"black"}}>
                            {User.username}
                        </Typography>
                    </div>
                </Toolbar>
            </AppBar>
            <div className="content">
                {children}
            </div>
        </div>
    );
}

export default Layout;
