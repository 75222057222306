import React, { useState } from "react";
import User from "./pages/User";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Posts from "./pages/Posts";
import "./App.css";
import Upload from "./pages/Upload";
import Login from "./pages/Login";
import "./index.css";
import { PostsProvider } from "./context/postcontext";
import Post from "./pages/photopost";
import Collection from "./pages/Collection";
import Layout from "./Layout";
import { UserProvider } from "./context/user";
import { CollectionProvider } from "./context/collectioncontext";
import AllCollections from "./pages/AllCollections";
const App = () => {
  // create a router

  const [user, setUser] = useState(
    localStorage.getItem(process.env.REACT_APP_USER_STORAGE_TOKEN)
  );

  return (
    <CollectionProvider>
      <PostsProvider>
        <UserProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/post/:id" element={<Posts />} />
              <Route
                path="/user/:id/photopost/:postid"
                element={
                  <Layout>
                    <Post />
                  </Layout>
                }
              />
              <Route path="/user/:id" element={<User />} />
              <Route
                path="/user/:id/collection/:collectionid"
                element={
                  <Layout>
                    <Collection />
                  </Layout>
                }
              />
              <Route
                path="/user/:id/allCollections"
                element={
                  <Layout>
                    <AllCollections />
                  </Layout>
                }
              />
              <Route path="/new" element={<Upload parentUser={user} />} />
              <Route
                path="/login"
                element={<Login setUser={setUser} parentUser={user} />}
              />
              <Route path="/" element={<Navigate to="/post/0" />} />
            </Routes>
          </BrowserRouter>
        </UserProvider>
      </PostsProvider>
    </CollectionProvider>
  );
};

export default App;
