export default function ProductOptionSelector({
  isActive,
  setIsActive,
  setValue,
}) {
  return (
    <>
      <div className={"upload-options-container" + (isActive ? " active" : "")}>
        <div className="upload-options-header">
          <h2>Add Product</h2>
          <button onClick={() => setIsActive(false)}>
            <i className="fa fa-times" />
          </button>
        </div>
        <div className="options">
          <div
            className="option"
            onClick={() => {
              setValue(1);
              setIsActive(false);
            }}
            style={{ cursor: "pointer" }}
          >
            <h4>Add existing product</h4>
            <i className="fa fa-chevron-right" />
          </div>
          <div
            className="option"
            onClick={() => {
              setValue(2);
              setIsActive(false);
            }}
            style={{ cursor: "pointer" }}
          >
            <h4>Create new product</h4>
            <i className="fa fa-chevron-right" />
          </div>
        </div>
      </div>
      <div
        className={"shadowed-overlay" + (isActive ? "" : " hidden")}
        onClick={() => setIsActive(false)}
      />
    </>
  );
}
