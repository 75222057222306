import React, { useContext, useEffect, useState } from 'react';
import { Card, CardMedia, CardContent, Typography, Button, Box } from '@mui/material';
import axios from 'axios';
import { ArrowForward } from '@mui/icons-material';
import { CollectionContext } from '../context/collectioncontext';
import { Link, useLocation, useParams } from 'react-router-dom';


const MyCuratedCollections = () => {
    const {id}=useParams()
    const {Collection,setCollection}=useContext(CollectionContext)
    const location=useLocation()
    const fetchcollections = (id) => {
        const url =
          process.env.REACT_APP_API_URL + "getAllCollections?user_id=" + id;
        const headers = {
          "Api-Key": process.env.REACT_APP_API_KEY,
        };
    
        console.log("fetching");
    
        axios
          .get(url, { headers })
          .then((response) => {
           
            console.log(response.data)
            setCollection(response.data);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    
useEffect(()=>{fetchcollections(id)},[!Collection.length])
  return (
    <div className='border-b border-gray-200'>
    <Box  sx={{ padding: 2 }}>
          <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
          <h1 className='font-bold text-xl'  gutterBottom>My Curated Collections</h1>
          <Link to={location.pathname+"/allcollections"}><Button >
          <Typography
      variant="body1"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: "#E91E63",
        textTransform: 'none',
        fontWeight: 'semibold',
         // Adjust the gap as needed
      }}
    >
      See All
      <ArrowForward fontSize='small' sx={{ color: "#E91E63" }} />
    </Typography>
        </Button></Link>
      </Box>
     
      <Typography variant="body2" fontSize={"small"} color="textSecondary" paragraph>
        Easy to style collections that are must haves this season. Check'em out!
      </Typography>
    
      <div className='flex justify-center'>
       {Collection.length && <Card sx={{ marginBottom: 2,width:"80%" }}>
        
          <CardMedia
            component="img"
            height="300"
            image={(Collection[0]?.Collection.image_link).startsWith('https://') ? Collection[0]?.Collection.image_link : `https://${Collection[0]?.Collection.image_link}`}
            alt={Collection[0]?.Collection.byline}
          />
          <CardContent sx={{display:"flex",
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center"
          }}>
            <Typography sx={{marginBottom:2,fontWeight:"bold"}} variant="h5">{Collection[0].Collection.byline}</Typography>
            <p className='font-medium'>
              {Collection[0].Collection.description}
              </p>
            <Link to={"/user/"+id+"/collection/"+Collection[0].Collection.id}>   <button className='bg-[#E91E63] mt-1 rounded-2xl px-4 font-medium text-white py-2' sx={{ marginTop: 1,bgcolor:"", }}>
              Shop Now
            </button></Link>
          </CardContent>
        </Card>
}
</div>
    </Box>
    </div>
  
  );
};

export default MyCuratedCollections;
