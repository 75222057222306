import React, { useState, useEffect } from "react";
import Reel from "./Reel";

const ReelsScroll = ({ reels }) => {
  const [activeReel, setActiveReel] = useState(0);
  const [centralIsMuted, setCentralIsMuted] = useState(true);
  let timer = null;
  let prevScroll = 0;

  const handleScrollWrapper = (e) => {
    if (timer != null) {
      clearTimeout(timer);
    }
    let deltaY = window.scrollY - prevScroll;
    prevScroll = window.scrollY;
    timer = setTimeout(() => {
      handleScroll(deltaY);
      timer = null;
    }, 200);
  };

  const handleScroll = (deltaY) => {
    const screenHeight = window.innerHeight;
    const scrollPosition = window.scrollY;
    const scrollExcess = scrollPosition % screenHeight;
    const baseScroll = Math.floor(scrollPosition / screenHeight);

    if (deltaY > 0) {
      if (scrollExcess > screenHeight / 4) {
        setActiveReel(baseScroll + 1);
      } else {
        setActiveReel(baseScroll);
      }
    } else {
      if (scrollExcess < (3 * screenHeight) / 4) {
        setActiveReel(baseScroll);
      } else {
        setActiveReel(baseScroll + 1);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScrollWrapper);

    return () => {
      window.removeEventListener("scroll", handleScrollWrapper);
    };
  }, [handleScrollWrapper]);

  return (
    <div className="reels-scroll" id="ContainerElementID">
      {reels.length ? (
        reels.map((reel, index) => (
          <Reel
            key={index}
            videoUrl={reel.video}
            activeIndex={activeReel}
            meta={reel}
            index={index}
            centralIsMuted={centralIsMuted}
            setCentralIsMuted={setCentralIsMuted}
          />
        ))
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReelsScroll;
