import { useEffect, useRef, useState } from "react";
import "./Products.css";
import Draggable from "react-draggable";

function Product({ product }) {
  // regex to extract a link from string
  const reg = /(https?:\/\/[^\s]+)$/g;

  // extract the link from the string
  let link = "";
  if (product.description) {
    const match = product.description.match(reg);
    if (match) {
      link = match[0];
    } else {
      link = product.product_link;
    }
  }

  return (
    <div className="product">
      <img src={product.image} alt={product.title} />
      <div className="product-info">
        <h3>{product.title.slice(0, 38)}</h3>
        <p>
          {/* rupees symbol font awesome */}
          <i className="fa fa-rupee-sign"></i>
          <span>{Number(product.sale_price)}</span>
          <span className="strike-through">{Number(product.price)}</span>
          <a href={link} target="_blank">
            View
          </a>
        </p>
      </div>
    </div>
  );
}

export default function Products({
  products,
  className,
  showProducts,
  setShowProducts,
}) {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const prodRef = useRef(null);

  const handleDrag = (e, ui) => {
    if (ui.y > 0) {
      setPosition({ x: 0, y: ui.y });
    } else {
      ui.y = 0;
      setPosition({ x: 0, y: 0 });
    }
  };

  const snapPosition = (e, ui) => {
    if (!prodRef) return;

    const divheight = prodRef.current.clientHeight;

    if (ui.y < divheight / 4) {
      setPosition({ x: 0, y: 0 });
    }

    if (ui.y > (2 * divheight) / 3) {
      setShowProducts(false);
    }
  };

  useEffect(() => {
    if (!prodRef.current) return;

    if (showProducts) {
      setPosition({ x: 0, y: 0 });
    } else {
      setPosition({ x: 0, y: prodRef.current.clientHeight * 2 });
    }
  }, [showProducts]);

  return (
    <div className={className}>
      <Draggable
        axis="y"
        handle="strong"
        position={position}
        onDrag={handleDrag}
        onStop={snapPosition}
      >
        <div
          className={"products " + (showProducts ? "" : "hidden")}
          ref={prodRef}
        >
          <strong>
            <h2>In this video</h2>
          </strong>
          <div className="product-container">
            {products.map((product, index) => (
              <Product key={index} product={product} />
            ))}
          </div>
          <div className="dummy-white" />
        </div>
      </Draggable>
    </div>
  );
}
