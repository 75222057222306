import React, { useContext,useEffect, useState } from 'react';
import { Card, CardHeader, CardContent, CardMedia, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { PostsContext } from '../context/postcontext';
import ProductsforPost from '../components/postproduct';
import axios from "axios";
const Post = () => {
    const [showProducts, setShowProducts] = useState(false);
    let { postid,id } = useParams();
    const { posts,setPosts } = useContext(PostsContext);
    const post = posts.find((post) => post.id === postid);
    const fetchposts = () => {
        const url =
          process.env.REACT_APP_API_URL + "getPostsByUser?user_id=" + id;
        const headers = {
          "Api-Key": process.env.REACT_APP_API_KEY,
        };
    
        console.log("fetching");
    
       axios
          .get(url, { headers })
          .then((response) => {
            setPosts(response.data);
          })
          .catch((e) => {
            console.error(e);
          });
      }

useEffect(() => {
if(posts.length==0){
   fetchposts()
    
}


}, [posts])









    const calculateTimeAgo = (createdAt) => {
        const postDate = new Date(createdAt);
        const currentDate = new Date();
        const timeDifference = currentDate.getTime() - postDate.getTime();
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        if (days > 0) {
            return `${days} day${days === 1 ? '' : 's'} ago`;
        } else if (hours > 0) {
            return `${hours} hour${hours === 1 ? '' : 's'} ago`;
        } else if (minutes > 0) {
            return `${minutes} minute${minutes === 1 ? '' : 's'} ago`;
        } else {
            return `${seconds} second${seconds === 1 ? '' : 's'} ago`;
        }
    };

    if (!post) {
        return <div>Loading...</div>;
    }

    return (
        <div className='user-container w-[420px]'>
            <Card>
                <CardHeader
                    subheader={` ${calculateTimeAgo(post.created_at)}`}
                />
                <CardContent>
                    {post.photos && post.photos.length > 0 && (
                        <CardMedia
                            component="img"
                            height="200"
                            image={"https://" + post.photos[0].Photo.url}
                            alt="Post image"
                        />
                    )}
                    <div className='mb-4'>
                        <Typography fontSize={"large"} variant="body1" color="textSecondary" component="p">
                            {post.description}
                        </Typography>
                    </div>
                    <button
                        className="shop-btn block mx-auto bg-[#E91E63] rounded-lg text-lg px-2 py-1 text-white"
                        onClick={() => {
                            setShowProducts(!showProducts);
                        }}
                    >
                        <i className="fa fa-tag" /> Shop Products
                    </button>
                    {showProducts && post.products && (
                      <div className='w-[420px]'>
                      <ProductsforPost products={post.products} setShowProducts={setShowProducts} showProducts={showProducts}/>
                      </div>  )}
                </CardContent>
            </Card>
        </div>
    );
};

export default Post;



