import { useContext, useEffect, useState } from "react";
import "./UserBio.css";
import axios from "axios";
import { UserContext } from "../../context/user";

export default function UserBio({ user }) {
  const { User, setUser } = useContext(UserContext);
  useEffect(() => {
    let ignore = false;

    const url = process.env.REACT_APP_API_URL + "showUserDetail";
    const data = {
      user_id: user,
    };
    const headers = {
      "Content-Type": "application/json",
      "Api-Key": process.env.REACT_APP_API_KEY,
    };

    console.log("UserBio");
    axios
      .post(url, data, { headers: headers })
      .then((response) => {
        console.log(response);
        if (ignore) return;
        if (response.data.code === 200) {
          setUser({
            username: response.data.msg.User.username,
            profile_pic: response.data.msg.User.profile_pic,
            bio: response.data.msg.User.bio,
          });
        }
      })
      .catch((e) => {
        console.error(e);
      });

    return () => {
      ignore = true;
    };
  }, [user]);

  return (
    <div className="border-b">
      <div className="user-cover">
        <img
          src="https://app.lehlah.club/_next/image?url=%2F_next%2Fstatic%2Fmedia%2Fdefaultcoverimage.ff701322.jpg&w=828&q=75"
          alt="cover"
        />
        <h1>Instazon</h1>
      </div>
      <div className="user-bio">
        <div className="user-avatar">
          <img src={User.profile_pic} alt="" />
        </div>
        <div className="user-details">
          <h2 className="text-xl">{User.username}</h2>
          <p>{User.bio}</p>
          <button className="follow-btn">Follow</button>
          <h1 className="text-xl text-black font-medium">
            Welcome to my Instazon !
          </h1>
        </div>
        {/* <div className="promotion">
                    <img className="logo" />
                    <div className="promotion-details">
                        <h3>Instazon</h3>
                        <p>Follow for more updates</p>
                    </div>
                    <button className="signup-btn">signup now</button>
                </div> */}
      </div>
    </div>
  );
}
