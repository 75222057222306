import { useState } from "react";
import LoginBox from "../components/Login/LoginBox";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";

export default function Login({ setUser, parentUser }) {
  let [loginType, setLoginType] = useState(0);
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const videoId = searchParams.get("videoId");

  if (videoId) {
    return <Navigate to={`/post/${videoId}`} />;
  }

  if (parentUser) {
    return (
      <Navigate
        to={
          location.state && location.state.redirect
            ? location.state.redirect
            : "/post/63"
        }
      />
    );
  }

  return (
    <div className="login-container">
      {loginType === 0 ? (
        <div className="login-options">
          <button onClick={() => setLoginType(1)}>Login with Email</button>
          <button onClick={() => setLoginType(2)}>Login with Phone</button>
        </div>
      ) : (
        <LoginBox
          type={loginType === 1 ? "email" : "phone"}
          setUser={setUser}
        />
      )}
    </div>
  );
}
