import { useState } from "react";
import "./NewProduct.css";
import ProductCard from "./ProductCard";
import axios from "axios";

export default function NewProduct({ goBack, insertProduct, parentUser }) {
  let [data, setData] = useState({
    title: "",
    description: "",
    price: "",
    discountedPrice: "",
    link: "",
    pic: "",
  });
  let [images, setImages] = useState(null);
  const acceptedTypes = ["image/png", "image/jpeg"];
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    setData((prevData) => {
      return {
        ...prevData,
        [e.target.name]: e.target.value,
      };
    });
  };

  const handleImageSubmit = (e) => {
    if (!e.target.files.length) return;
    for (let i = 0; i < e.target.files.length; i++) {
      if (!acceptedTypes.includes(e.target.files[i].type)) {
        alert("Only .png and .jpeg file types are allowed");
        return;
      }
    }
    setImages(e.target.files[0]);
    setData({
      ...data,
      pic: URL.createObjectURL(e.target.files[0]),
    });
  };

  const generateTemplate = (data) => {
    let d_cpy = {
      title: data.title,
      description: data.description,
      price: data.price,
      sale_price: data.discountedPrice,
      image: data.pic,
    };
    if (d_cpy.title === "") {
      d_cpy.title = "Product name";
    }
    if (d_cpy.description === "") {
      d_cpy.description = "Product Description";
    }

    return d_cpy;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!images) {
      alert("Upload an image!");
      return;
    }

    if (!window.confirm("Confirm to add a new product")) return;

    setLoading(true);

    const url = process.env.REACT_APP_API_URL + "addProductNew";
    const headers = {
      "Api-Key": process.env.REACT_APP_API_KEY,
      // "User-Id": "1",
    };

    let formdata = new FormData();
    formdata.append("user_id", parentUser);
    formdata.append("title", data.title);
    formdata.append("description", data.description);
    formdata.append("price", data.price);
    formdata.append("discounted_price", data.discountedPrice);
    formdata.append("link", data.link);
    formdata.append("product_image", images);

    axios
      .post(url, formdata, { headers: headers })
      .then((response) => {
        if (response.data.code === 200) {
          const product = {
            id: response.data.product.id,
            title: response.data.product.title,
            description: response.data.product.description,
            price: response.data.product.price,
            sale_price: response.data.product.sale_price,
            image: "https://" + response.data.product.image,
          };

          insertProduct(product);
          goBack();
        }
      })
      .catch((e) => {
        alert("Could not add the product");
        console.error(e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <form className="new-product-container" onSubmit={handleSubmit}>
      <div className="new-product-body">
        <div className="upload-header">
          <h2>New Product</h2>
          <button onClick={goBack}>
            <i className="fa fa-arrow-left" />
          </button>
        </div>
        <div className="upload-images">
          <div className="image-uploader">
            <input
              type="file"
              accept=".png,.jpeg"
              onChange={handleImageSubmit}
            />
            <label>
              <i className="fa fa-plus" />
              <p>{images ? "Change" : "Upload"} Image</p>
            </label>
          </div>
        </div>
        <div className="product-data">
          <div className="input-group">
            <input
              type="text"
              name="title"
              value={data.name}
              onChange={handleChange}
              required
            />
            <label>Product Name</label>
          </div>
          <div className="input-group">
            <input
              type="text"
              name="description"
              value={data.description}
              onChange={handleChange}
              required
            />
            <label>Description</label>
          </div>
          <div className="half-input-group">
            <div className="input-group">
              <input
                type="number"
                name="price"
                value={data.price}
                onChange={handleChange}
                required
              />
              <label>Price</label>
            </div>
            <div className="input-group">
              <input
                type="number"
                name="discountedPrice"
                value={data.discountedPrice}
                onChange={handleChange}
                required
              />
              <label>Discounted Price</label>
            </div>
          </div>
          <div className="input-group">
            <input
              type="text"
              name="link"
              value={data.link}
              onChange={handleChange}
              required
            />
            <label>Product Link</label>
          </div>
        </div>
        <div className="template-display">
          <h4>How it looks</h4>
          <ProductCard
            data={generateTemplate(data)}
            removeProduct={(id) => true}
            isTemplate={true}
          />
        </div>
      </div>
      <button type="submit" disabled={loading}>
        Add{loading ? "ing" : ""} Product
      </button>
    </form>
  );
}
