import axios from 'axios';
import React, { createContext, useState } from 'react';

export const CollectionContext = createContext();

export const CollectionProvider = ({ children }) => {
  const [Collection, setCollection] = useState([]);

  
  return (
    <CollectionContext.Provider value={{ Collection, setCollection }}>
      {children}
    </CollectionContext.Provider>
  );
};
